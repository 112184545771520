<template>
  <div class="content">
    <van-image class="success" :src="require('@/assets/images/clue.png')"></van-image>
    <div class="tips">支付成功</div>
    <div class="paid">实付<span class="amount">￥{{amount}}</span></div>
    <van-button
      type="primary"
      color="#FF9800"
      block
      round
      @click="back"
      native-type="button"
      class="back-btn">
      返回
    </van-button>
    <iframe
      v-if="frameFlag"
      id="iframeId"
      :src="frameUrl"
      frameborder="0"
      scrolling="no"
      class="frame"> 
    </iframe>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        redirect_url:'',
        amount: '',
        frameFlag: false,
        frameUrl: '',
      }
    },
    created() {
      this.redirect_url=decodeURIComponent(this.$route.query.redirect_url) || ''
      this.amount = this.$route.query.amount
      const marketUrl = window.location.href.includes('cangoonline.net')
            ? 'https://market.cangoonline.net'
            : 'https://market-uat.cangoelectronics.com'

      window.addEventListener('getAppParams', (event) => {
        const res = JSON.parse(event.detail)
        if (window.plus) {
          const appId = res.appId
          this.frameFlag = appId === 'rest-2buser-center'
          this.frameUrl = `${marketUrl}/service?appId=${res.appId}&token=${res.token}&appToken=${res.appToken}&redirect=${this.redirect_url}`
        }
      })
      this.getAppParams()
    },
    methods: {
      back() {
        if (this.redirect_url) {
          window.location.replace(this.redirect_url)
        } else {
          window.history.back()
        }
      },
      getAppParams() {
        document.addEventListener('UniAppJSBridgeReady', () => {
          this.getAppParamsInner()
        })
        this.getAppParamsInner()
      },
      getAppParamsInner() {
        uni.postMessage({
          data: {
            action: 'getAppParams',
            responseCallback: 'getAppParams',
          }
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
.content{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.success{
  margin-top: 60px;
  width: 150px;
  height: 118px;
}
.tips{
  color: #101D36;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.paid{
  margin-top: 30px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #101D36;
  .amount{
    font-size: 26px;
    color: #FF6A00;
  }
}

.back-btn{
  width: 100px;
  height: 45px;
  margin-top: 35px;
  margin-bottom: 30px;
}
.frame {
  width: 100%;
  flex-grow: 1;
}
</style>